import request from '@/utils/request'

// 客户API列表
export function findAgentsApiInterfaces(params) {
  return request({
    url: `/agents_api_interfaces`,
    method: 'get',
    params: params
  })
}

// 更新API是否开通
export function updateAgentsApiInterfacesIsOpen(recordId, data) {
  return request({
    url: `/agents_api_interfaces/${recordId}/is_open`,
    method: 'patch',
    data
  })
}
