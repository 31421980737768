<template>
  <div>
    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <div slot="is_open" slot-scope="is_open, record">
        <a-switch
          :checked="is_open"
          :loading="editingIsOpenId === record.id"
          @change="(checked) => changeIsOpen(checked, record)"
          v-if="isHasApiSettingPermission"
        />
        <span v-else>
          {{ record.is_open_display }}
        </span>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/index'
import { findAgentsApiInterfaces, updateAgentsApiInterfacesIsOpen } from '@/api/agents_api_interface'
import { hasPermission } from '@/utils/permission'

export default {
  name: 'AgentApiList',
  components: {
    Pagination
  },
  props: {
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: [],
      loading: true,
      editingIsOpenId: 0,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '接口名称',
          dataIndex: 'name'
        },
        {
          title: '接口类型',
          dataIndex: 'api_type'
        },
        {
          title: '是否开通',
          dataIndex: 'is_open',
          scopedSlots: { customRender: 'is_open' }
        }
      ]
    },

    isHasApiSettingPermission() {
      return hasPermission('agents/api_setting')
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      findAgentsApiInterfaces(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    changeIsOpen(isOpen, record) {
      const vm = this
      this.$confirm({
        title: isOpen ? '确定开启该接口权限吗?' : '确定关闭权限吗？关闭后，客户将无法使用该接口',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingIsOpenId = record.id
          updateAgentsApiInterfacesIsOpen(record.id, { is_open: isOpen })
            .then((res) => {
              vm.editingIsOpenId = 0
              if (res.code === 0) {
                record.is_open = isOpen
              }
            })
            .catch(() => {
              vm.editingIsOpenId = 0
            })
        }
      })
    }
  }
}
</script>
